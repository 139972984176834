import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'

export class ComingSoon extends Component {
  render() {
    return (
      <Container>
        <Row className="pad">
          <Col sm={{ size: 8, offset: 2 }} className="text-center">
            <h1 className="big-blue-mon">Esta sección del sitio web se encuentra en construcción.</h1>
            <i className="fa fa-5x mt-3 fa-user-clock" style={{ color: '#27387e' }} />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ComingSoon
