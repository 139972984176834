import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate, ABanner } from 'shared/components/common'
import { ComingSoon } from 'shared/components/ComingSoon/ComingSoon'

type AvisoLegalProps = PageProps
interface AvisoLegalState {
  mounted: boolean
  showing: boolean
}
class AvisoLegal extends Component<AvisoLegalProps, AvisoLegalState> {
  state = {
    mounted: false,
    showing: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <React.Fragment>
            <ABanner title="Aviso legal" titleClassName="big-white" titleSize={12} />
            <ComingSoon />
          </React.Fragment>
        </AAnimate>
      </Layout>
    )
  }
}

export default AvisoLegal
